import React, { useState }  from "react"
import axios from "axios";

const ContactForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/f2e2e2ef-4a94-463e-868b-c43f3df55fcc",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <section className="section contact-form">
      <div className="container">
        <h3>Contact us</h3>
        <p>Keep updated about future events and offers</p>
        <form onSubmit={handleOnSubmit}>
          <input type="hidden" name="_gotcha" className="hidden" />
          <input type="email" name="email" placeholder="Your Email" required />
          <input type="text" name="name" placeholder="Your Name" required />
          <textarea type="text" name="message" placeholder="Your Message" />
          <button className="btn large" type="submit">Send</button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
