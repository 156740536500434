import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const FeatureGrid = ({ gridItems }) => (
  <Swiper
    modules={[Navigation]}
    navigation
  >
    {gridItems.map((item) => (
      <SwiperSlide key={item.text}>
        <div>
          <PreviewCompatibleImage imageInfo={item} />
          <div className="content">
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
